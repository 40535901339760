import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import styled from '@emotion/styled'
import Author from '../entities/author'
import Article from '../entities/article'
import Testimony from '../entities/testimony'
import { FeaturedHero, Layout, Listing, Section, Wrapper, Row, Column, NewCarousel } from '../components'
import website from '../../config/website'
import { framework } from '../../config/theme'

const SectionsZone = styled.div``
const Main = Wrapper.withComponent('main')

const Index = ({ data: { homepage, articles, testimonies } }) => {
  const heroContent = {
    title: homepage.data.title.text,
    headline: homepage.data.headline.html,
    image: homepage.data.hero_image,
    properties: { overlay: true, height: '25rem' },
  }

  return (
    <Layout>
      <FeaturedHero {...heroContent} invert />
      <NewCarousel />
      <Main id={website.skipNavId} zones>
        <SectionsZone className="zone">
          {homepage.data.body.map(({ primary }) => (
            <Section
              key={_.kebabCase(primary.section_title.text)}
              title={primary.section_title.text}
              url={primary.section_link && primary.section_link.url}
              html={primary.section_body.html}
              image={primary.section_image}
              className={primary.section_class}
            />
          ))}
        </SectionsZone>

        <Row className="zone" separateColumns={{ default: framework.unit.default, bp: { s: framework.unit.s } }}>
          {articles.edges.length > 0 && (
            <Column>
              <Listing
                title={`Recente ${Article.entity.labels.plural}`}
                edges={articles.edges}
                link={edge => Article.entity.urls.link(edge.node)}
              />
            </Column>
          )}

          {testimonies.edges.length > 0 && (
            <Column>
              <Listing
                title={`Recente ${Testimony.entity.labels.plural}`}
                edges={testimonies.edges}
                link={edge => Testimony.entity.urls.link(edge.node)}
                extra={edge =>
                  edge.node.data.author ? (
                    <Link to={Author.entity.urls.link(edge.node.data.author)}>
                      {edge.node.data.author.document[0].data.name}
                    </Link>
                  ) : null
                }
              />
            </Column>
          )}
        </Row>
      </Main>
    </Layout>
  )
}

export default Index

Index.propTypes = {
  data: PropTypes.shape({
    articles: PropTypes.object.isRequired,
    testimonies: PropTypes.object.isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  query IndexPage {
    homepage: prismicHomepage {
      data {
        title {
          text
        }
        headline {
          html
        }
        hero_image {
          localFile {
            ...HeroImage
          }
        }
        body {
          primary {
            section_class
            section_link {
              url
            }
            section_title {
              text
            }
            section_body {
              html
            }
            section_image {
              localFile {
                ...SectionImage
              }
            }
          }
        }
      }
    }
    articles: allPrismicArticle(
      limit: 2
      sort: { fields: [data___date], order: DESC }
      filter: { data: { enabled: { eq: "Yes" } } }
    ) {
      ...Articles
    }
    testimonies: allPrismicTestimony(
      limit: 2
      sort: { fields: last_publication_date, order: DESC }
      filter: { data: { external: { ne: "Yes" }, enabled: { eq: "Yes" } } }
    ) {
      ...Testimonies
    }
  }
`
